import React from 'react';
import BoxLayout from '../../components/Ui/BoxLayout';
import { Text, Button, Flex } from '../../components/Ui';
import bgTurisanda from '../../assets/images/turisanda_home_ban.jpg';
import { Link } from 'react-router-dom';
const CartPageSuccess = () => {
  return (
    <>
      <div className='mb-20'>
        <BoxLayout
          title={'GRAZIE PER IL TUO ORDINE'}
          styling={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Text size={18}>La tua richiesta è stata presa in gestione.</Text>
          <Text size={18}>Riceverai presto una e-mail con tutti i dettagli.</Text>
          <Flex justify='flex-end' align='center'>
            <Link to='/turisanda'>
              <Button hover active>
                TORNA ALLA HOME PAGE
              </Button>
            </Link>
          </Flex>
        </BoxLayout>
      </div>
    </>
  );
};
export default CartPageSuccess;
